/* Actions */
.App-actions {
  margin: auto 24px;
  padding: 24px;
}
.App-actions .button {
  background-color: #57068C; /* purple */
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;

  margin-bottom: 24px;
  padding: 12px;
  width: 100%;
}
.App-actions .links {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;

  text-align: center;
}
.App-actions .link {
  cursor: pointer;
  margin-bottom: 8px;
}
