/* Reservation Details */
.App-reservation-details .title {
  font-size: 24px;
  font-weight: 700;
  margin: auto;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
}

.App-reservation-details .label {
  color: #57068C; /* purple */
  font-weight: 700;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.App-reservation-details .value {
  margin-bottom: 16px;
}
.App-reservation-details .logo {
  text-align: center;
}
.App-reservation-details .logo img {
  width: 205px;
  height: 205px;
}
.App-reservation-details hr {
  margin: 16px auto;
}
.App-reservation-details .hint {
  text-align: center;
}
.App-reservation-details .detail-box {
  border: 2px solid #57068C; /* purple */
  color: #57068C; /* purple */

  margin-bottom: 24px;
  padding: 16px;

  text-align: center;
  text-transform: uppercase;

}
