/* Header Section */
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 390px;

  padding: 16px;

  background-color: #57068C; /* purple */
  color: white;
  text-align: center;
}
.App-header h1 {
  margin-bottom: 16px;
}
.App-header p {
  margin-bottom: 16px;
}
.App-header h2 {
  margin-bottom: 24px;
}
