/* Resets */
* {
  padding: 0;
  margin: 0;
}

body {
  border: 2px solid #F7F7F7;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Globals and Spacing */
  font-family: 'Lato', sans-serif;
  width: 100vw;
  max-width: 468px;
  min-height: 100vh;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Typography */
h1 {
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}
h2 {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}
p {
  font-size: 18px;
}
.link {
  color: #000;
  font-size: 14px;
}
