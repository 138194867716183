.App-loading {
  opacity: 0.1;
}
.App-loaded {
  opacity: 1;
  transition: opacity 1.5s ease-in-out;
}

/* Content Area */
.App-content .loader {
  opacity: 0.5;
}

/* Content Box */
.App-content-box {
  background-color: #FFFFFF;
  margin: auto 16px;
  padding: 24px;

  border: 2px solid #57068C; /* purple */
}
